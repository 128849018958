import React from 'react'
import Header from '../../layout/Header'
import Footer from '../../layout/Footer'
import ExampleWithProviders from '../../components/productcomponent/Crud'

const Product = () => {
  return (
    <>
    <Header/>
    <ExampleWithProviders/>
    <Footer/>
    </>
  )
}

export default Product