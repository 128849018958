import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Reset = () => {
    const { id } = useParams();
   
    const navigate = useNavigate();
  const [data, setData] = useState({
    email: "",
    newpassword: "",  
    confirmpassword: "",  
  });

  function handleInputs(e) {
    const { name, value } = e.target;
    setData((pre) => ({
      ...pre,
      [name]: value,
    }));
  }

  function handleForm(e) {
    e.preventDefault();
    console.log("apiData",id);
    // console.log(data, "data");

    // if (data.newpassword !== data.confirmpassword) {
    //   toast.error("Passwords do not match");
    //   return;
    // }

    const apiData = new FormData();
   
    apiData.append("newpassword", data.newpassword);
  
    apiData.append("id", id);
    axios
      .post(`https://ecommerce99.pythonanywhere.com/Admin/resetPassword/`, apiData)
      .then((res) => {
        console.log(res, "response");
        if (res.data.status === true) {
          toast.success(res.data.message);
          setData({
       
            newpassword: "",
          });
          navigate("/login");
        }
      })
      .catch((err) => {
        console.error("Error resetting password: ", err);
      });
  }

  return (
    <div className="main-div-login">
      <h1 className="login-second">Markaz-e-hiraj </h1>
      <div className="input-div-login">
        <form className="login-main-form" onSubmit={handleForm}>
    
          <input
            className="login-input"
            type="password"
            name="newpassword"
            id="newpassword"
            value={data?.newpassword}
            onChange={handleInputs}
            placeholder="New Password"  
          />
         
          <br />
          <div className="d-flex">
            <div className="buttons-login">
              <button
                onClick={handleForm}
                type="button"
                className="login-button"
              >
                submit Password
              </button>
            </div>

            {/* <div className="buttons-login">
              <button
                type="submit"
                className="login-button"
              >
                Reset Password
              </button>
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Reset;
