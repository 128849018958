import React, { useState, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PencilSquare, Eye, Trash } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import {
  //  IconButton,
   MenuItem, 
  //  Tooltip
   } from "@mui/material";
import Header from "../../layout/Header";
import ClearIcon from '@mui/icons-material/Clear';
import { MdCancel } from "react-icons/md";
import {axiosIntance} from '../../config/https'

const validationSchema = Yup.object({
  Category_Name: Yup.string().min(2).max(25).required(" please enter your Category Name"),
  Description: Yup.string().min(2).max(25).required(" please enter your Description"),
  ImageUrl: Yup.mixed().required("File is required")
});

console.log(axiosIntance);
axiosIntance.get('/Rider/Order/Detail/')

const CategoryContent = () => {
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  // const handleShow3 = () => setShow3(true);
  const [selectedUser, setSelectedUser] = useState(null);

  
  const handlePreviewClick = (user) => {
    setSelectedUser(user)
};

  const [modalData, setModalData] = useState({
    show: false,
    formData: {
      Category_Name: "",
      Description: "",
      ImageUrl:''
    },
    isEditMode: false,
  });

  const [tableData, setTableData] = useState([]);

  const uniqueIdGenerator = () => uuidv4();

  const columns = useMemo(
    () => [
      {
        accessorKey: "Category_Name",
        header: "Category_Name",
        size: 150,
      },
      {
        accessorKey: "Description",
        header: "Description",
        size: 150,
      },
      {
        accessorKey: "ImageUrl",
        header: "ImageUrl",
        size: 150,
      },
    
     
    ],
    []
  );

  const handleEdit = (data) => {
    setModalData({
      show: true,
      formData: { ...data },
      isEditMode: true,
    });
  };

  const handleView = (data) => {
    console.log("View clicked", data);
  };

  const handleDelete = (id) => {
    const updatedData = tableData.filter((item) => item.id !== id);
    setTableData(updatedData);
  };

 

  const handleClose = () => {
    setModalData((prevData) => ({
      ...prevData,
      show: false,
    }));
  };

  const handleFormSubmit = () => {
    handleSubmit();
    const formValues = values;
    if (!errors || Object.keys(errors).length === 0) {
      if (modalData.isEditMode) {
        const updatedData = tableData.map((item) =>
          item.id === modalData.formData.id ? { ...item, ...formValues } : item
        );
        setTableData(updatedData);
      } else {
        setTableData((prevData) => [
          ...prevData,
          {
            id: uniqueIdGenerator(),
            ...formValues,
            edit: (
              <Button variant="link" onClick={() => handleEdit(formValues)}>
                <PencilSquare />
              </Button>
            ),
            view: (
              <Button variant="link" onClick={() => handleView()}>
                <Eye />
              </Button>
            ),
            delete: (
              <Button
                variant="link"
                onClick={() => handleDelete(formValues.id)}
              >
                <Trash />
              </Button>
            ),
          },
        ]);
      }

      handleClose();
    }
  };

  const { values, errors, touched, handleBlur, handleSubmit, handleChange } =
    useFormik({
      initialValues: modalData.formData,
      validationSchema: validationSchema,
      onSubmit: () => {},
    });

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => [
      <>
        <div className="d-flex">
          <MenuItem key="edit" onClick={() => handleEdit(row.original.data)}>
            <PencilSquare />
          </MenuItem>
          
          <MenuItem key="view" onClick={() => handleView(row.original)}>
          <Eye  onClick={handlePreviewClick}/>
          </MenuItem>
          
          <MenuItem key="delete" onClick={() => handleDelete(row.original.id)}>
            <Trash />
          </MenuItem>
          
        </div>
      </>
    ],
  });

  return (
    <>
    <Header/>


      <div className="app-content content">
        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">

            <div className="container ">
 
             {/* ////////////////////////////////  Model Preview /////////////////////////// */}

             <Modal show={show3} onHide={handleClose3}>
                            <Modal.Header >
                                <Modal.Title>Category Detail</Modal.Title>
                                <Modal.Title className='cursor-pointer' onClick={handleClose3}><ClearIcon /></Modal.Title>
                            </Modal.Header>
                            {selectedUser && (
                        <div className="mt-1 mx-3 mb-2 " >
                            <div className='p-2'>
                            <h2 className='font-weight-bold'>Preview</h2>
                            <p className='mt-1'>    
                                <strong>Category Name:</strong> {selectedUser.Category_Name }
                            </p>
                            <p>
                                <strong>Description :</strong> {selectedUser.Description}
                            </p>
                            <p>
                                <strong>Image URL:</strong> {selectedUser.ImageUrl}
                            </p>
                           
                            {/* Add image tag here if you want to display the image */}
                            
                            </div>
                        </div>
                    )}
                        </Modal>


                        {/* ////////////////       ENd //////////////////////////// */}
  


             <div className="mb-2">
               <Button variant="primary"
               onClick={() => {
                setModalData({
                  formData: {
                    Category_Name: "",
                    Description: "",
                    ImageUrl:''
                  },
                  isEditMode: false,
                  show: true,
                });
              }}>
                 Add category +
               </Button>
             </div>
           </div>
            <MaterialReactTable
              table={table}
            />
          </div>
        </div>
      </div>
      <Modal show={modalData.show} onHide={handleClose}>
        <Modal.Header style={{ backgroundColor: "#372f2f", color: "white" }} >
          <Modal.Title><h3 className="text-white">Add Your Catergory</h3></Modal.Title>
          <MdCancel size={30} onClick={handleClose}/>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
           <div className="d-flex justify-content-center mt-2 justify-content-around">
            <Form.Group className="mb-3" controlId="Name">
              <Form.Label>Category_Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Category_Name"
                name="Category_Name"
                value={values.Category_Name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.Category_Name && touched.Category_Name ? (
                <p className="color-change">{errors.Category_Name}</p>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Description"
                name="Description"
                value={values.Description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.Description && touched.Description ? (
                <p className="color-change">{errors.Description}</p>
              ) : null}
            </Form.Group>
            </div> 
            <div className="container px-2">
            <Form.Group className="mb-2" controlId="Vehicle">
              <Form.Label>ImageUrl:</Form.Label>
              <Form.Control
                type="file"
                placeholder="Enter your vehicle number"
                name="ImageUrl"
                value=''
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.ImageUrl && touched.ImageUrl ? (
                <p className="color-change">{errors.ImageUrl}</p>
              ) : null}
            </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#372f2f"}}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleFormSubmit}>
            submit
          </Button>
        </Modal.Footer >
      </Modal>
    </>
  );
};

export default CategoryContent;

