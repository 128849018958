import React, { useState } from "react";
// import { FaBeer } from "react-icons/fa";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Link } from "react-router-dom";
import { navbartoggle } from "../Redux/Action/ActionFunction";
// import { MdKeyboardArrowRight } from "react-icons/md";
import { BiSolidCategory } from "react-icons/bi";
import { FaPiedPiperPp } from "react-icons/fa";
import { GrUserManager } from "react-icons/gr";
import { IoLogOut } from "react-icons/io5";
import { MdNordicWalking, MdOutlineProductionQuantityLimits } from "react-icons/md";
import { SiRider } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";

import { CgProfile } from "react-icons/cg";
import { toast } from "react-toastify";

const Header = () => {
  const dispatch = useDispatch();
  const mystate = useSelector((state) => state.Counter);
  console.log("value of counter", mystate);
  // const [open, setopen] = useState(false);
  const [Togglebtn, setTogglebtn] = useState(false);
  const [Togglemobile, setTogglemobile] = useState(false);
  const [Profilebtn, setProfilebtn] = useState(false);

  // const myopenfunction = () => {
  //   dispatch(navbartoggle(!mystate));
  //   // console.log("value of state is ===>",open)
  // };

  const togglefunction = () => {
    setTogglebtn(!Togglebtn);
    // setIsHovering(isHovering);
    console.log("value of toggle", Togglebtn);
    if (Togglebtn === false) {
      document.body.classList.add("menu-collapsed");

      document.body.classList.remove("menu-expanded");
    } else {
      document.body.classList.add("menu-expanded");
      document.body.classList.remove("menu-collapsed");
    }
  };
  const [isHovering, setIsHovering] = useState(false);

  // const [isHovering2, setIsHovering2] = useState(false);

  // const handleMouseOver = () => {
  //     setIsHovering(!isHovering);
  // setTogglebtn(!Togglebtn)
  //     console.log("i am running")
  //   };
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  // const handleMouseOver2 = () => {
  //     setIsHovering2(true);
  // };

  // const handleMouseOut2 = () => {
  //     setIsHovering2(false);
  // };

  const ahmed = () => {
    // alert("hello balohc")

    // open
    // vertical-layout 2-columns fixed-navbar vertical-overlay-menu pace-done menu-open

    // close
    // vertical-layout 2-columns fixed-navbar vertical-overlay-menu pace-done menu-hide
    // vertical-layout 2-columns fixed-navbar vertical-overlay-menu pace-done menu-hide
    setTogglemobile(!Togglemobile);
    if (Togglemobile === false) {
      document.body.classList.add(
        "vertical-layout",
        "2-columns",
        "fixed-navbar",
        "vertical-overlay-menu",
        "pace-done",
        "menu-hide"
      );
      document.body.classList.remove(
        "vertical-menu-modern",
        "menu-expanded",
        "menu-open"
      );
    } else {
      document.body.classList.add(
        "menu-open"
        // vertical-layout 2-columns fixed-navbar vertical-overlay-menu pace-done menu-open
      );

      document.body.classList.remove("menu-hide");
    }
  };

  const profilebtnfun = () => {
    setProfilebtn(!Profilebtn);
  };

  return (
    <>
      <nav className="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-semi-dark navbar-shadow">
        <div className="navbar-wrapper">
          {/* working */}
          {/* <div className="navbar-header expanded" > */}
          <div
            className={
              isHovering ? "navbar-header expanded  " : "navbar-header "
            }
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <ul className="nav navbar-nav flex-row">
              <li className="nav-item mobile-menu d-md-none mr-auto">
                <Link
                  onClick={ahmed}
                  className="nav-link nav-menu-main menu-toggle hidden-xs"
                  to="#"
                >
                  <i className="ft-menu font-large-1" />
                </Link>
              </li>
              <li className="nav-item mr-auto">
                <Link className="navbar-brand" to="/">
                  <img
                    className="brand-logo"
                    alt="modern admin logo"
                    src="../../../app-assets/images/logo/logo.png"
                  />
                  <h3 className="brand-text">Farmer Spot</h3>
                </Link>
              </li>
              <li className="nav-item d-none d-md-block float-right">
                <Link
                  className="nav-link modern-nav-toggle pr-0"
                  data-toggle="collapse"
                  to="/"
                >
                  <i
                    className={
                      Togglebtn
                        ? "toggle-icon font-medium-3 white ft-toggle-left"
                        : "toggle-icon ft-toggle-right font-medium-3 white"
                    }
                    onClick={togglefunction}
                    data-ticon="ft-toggle-right"
                  />
                </Link>
              </li>
              <li className="nav-item d-md-none">
                <Link
                  className="nav-link open-navbar-container"
                  to="/"
                  data-toggle="collapse"
                  data-target="#navbar-mobile"
                >
                  <i className="la la-ellipsis-v" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="navbar-container content">
            <div className="collapse navbar-collapse" id="navbar-mobile">
              <ul className="nav navbar-nav mr-auto float-left">
                <li className="nav-item d-none d-md-block">
                  <Link className="nav-link nav-link-expand" to="/">
                    <i className="ficon ft-maximize" />
                  </Link>
                </li>
                <li className="dropdown nav-item mega-dropdown">
                  <Link
                    className="dropdown-toggle nav-link"
                    to="/"
                    data-toggle="dropdown"
                  >
                    Mega
                  </Link>
                  <ul className="mega-dropdown-menu dropdown-menu row">
                    <li className="col-md-2">
                      <h6 className="dropdown-menu-header text-uppercase mb-1">
                        <i className="la la-newspaper-o" /> News
                      </h6>
                      <div id="mega-menu-carousel-example">
                        <div>
                          <img
                            className="rounded img-fluid mb-1"
                            src="../../../app-assets/images/slider/slider-2.png"
                            alt="First slide"
                          />
                          <Link className="news-title mb-0" to="/">
                            Poster Frame PSD
                          </Link>
                          <p className="news-content">
                            <span className="font-small-2">
                              January 26, 2018
                            </span>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="col-md-3">
                      <h6 className="dropdown-menu-header text-uppercase">
                        <i className="la la-random" /> Drill down menu
                      </h6>
                      <ul className="drilldown-menu">
                        <li className="menu-list">
                          <ul>
                            <li>
                              <Link className="dropdown-item" to="/">
                                <i className="ft-file" /> Page layouts &amp;
                                Templates
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <i className="ft-align-left" /> Multi level menu
                              </Link>
                              <ul>
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    <i className="la la-bookmark-o" /> Second
                                    level
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    <i className="la la-lemon-o" /> Second level
                                    menu
                                  </Link>
                                  <ul>
                                    <li>
                                      <Link className="dropdown-item" to="#">
                                        <i className="la la-heart-o" /> Third
                                        level
                                      </Link>
                                    </li>
                                    <li>
                                      <Link className="dropdown-item" to="#">
                                        <i className="la la-file-o" /> Third
                                        level
                                      </Link>
                                    </li>
                                    <li>
                                      <Link className="dropdown-item" to="#">
                                        <i className="la la-trash-o" /> Third
                                        level
                                      </Link>
                                    </li>
                                    <li>
                                      <Link className="dropdown-item" to="#">
                                        <i className="la la-clock-o" /> Third
                                        level
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    <i className="la la-hdd-o" /> Second level,
                                    third link
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    <i className="la la-floppy-o" /> Second
                                    level, fourth link
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/">
                                <i className="ft-camera" /> Color palette system
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="sk-2-columns.html"
                              >
                                <i className="ft-edit" /> Page starter kit
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/">
                                <i className="ft-minimize-2" /> Change log
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li className="col-md-3">
                      <h6 className="dropdown-menu-header text-uppercase">
                        <i className="la la-list-ul" /> Accordion
                      </h6>
                      <div
                        id="accordionWrap"
                        role="tablist"
                        aria-multiselectable="true"
                      >
                        <div className="card border-0 box-shadow-0 collapse-icon accordion-icon-rotate">
                          <div
                            className="card-header p-0 pb-2 border-0"
                            id="headingOne"
                            role="tab"
                          >
                            <Link
                              data-toggle="collapse"
                              data-parent="#accordionWrap"
                              to="#accordionOne"
                              // aria-expanded="true"
                              aria-controls="accordionOne"
                            >
                              Accordion Item #1
                            </Link>
                          </div>
                          <div
                            className="card-collapse collapse show"
                            id="accordionOne"
                            role="tabpanel"
                            aria-labelledby="headingOne"
                            // aria-expanded="true"
                          >
                            <div className="card-content">
                              <p className="accordion-text text-small-3">
                                Caramels dessert chocolate cake pastry jujubes
                                bonbon. Jelly wafer jelly beans. Caramels
                                chocolate cake liquorice cake wafer jelly beans
                                croissant apple pie.
                              </p>
                            </div>
                          </div>
                          <div
                            className="card-header p-0 pb-2 border-0"
                            id="headingTwo"
                            role="tab"
                          >
                            <Link
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordionWrap"
                              to="#accordionTwo"
                              // aria-expanded="false"
                              aria-controls="accordionTwo"
                            >
                              Accordion Item #2
                            </Link>
                          </div>
                          <div
                            className="card-collapse collapse"
                            id="accordionTwo"
                            role="tabpanel"
                            aria-labelledby="headingTwo"
                            // aria-expanded="false"
                          >
                            <div className="card-content">
                              <p className="accordion-text">
                                Sugar plum bear claw oat cake chocolate jelly
                                tiramisu dessert pie. Tiramisu macaroon muffin
                                jelly marshmallow cake. Pastry oat cake chupa
                                chups.
                              </p>
                            </div>
                          </div>
                          <div
                            className="card-header p-0 pb-2 border-0"
                            id="headingThree"
                            role="tab"
                          >
                            <Link
                              className="collapsed"
                              data-toggle="collapse"
                              data-parent="#accordionWrap"
                              to="#accordionThree"
                              // aria-expanded="false"
                              aria-controls="accordionThree"
                            >
                              Accordion Item #3
                            </Link>
                          </div>
                          <div
                            className="card-collapse collapse"
                            id="accordionThree"
                            role="tabpanel"
                            aria-labelledby="headingThree"
                            // aria-expanded="false"
                          >
                            <div className="card-content">
                              <p className="accordion-text">
                                Candy cupcake sugar plum oat cake wafer marzipan
                                jujubes lollipop macaroon. Cake dragée jujubes
                                donut chocolate bar chocolate cake cupcake
                                chocolate topping.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="col-md-4">
                      <h6 className="dropdown-menu-header text-uppercase mb-1">
                        <i className="la la-envelope-o" /> Contact Us
                      </h6>
                      <form className="form form-horizontal">
                        <div className="form-body">
                          <div className="form-group row">
                            <label
                              className="col-sm-3 form-control-label"
                              htmlFor="inputName1"
                            >
                              Name
                            </label>
                            <div className="col-sm-9">
                              <div className="position-relative has-icon-left">
                                <input
                                  className="form-control"
                                  type="text"
                                  id="inputName1"
                                  placeholder="John Doe"
                                />
                                <div className="form-control-position pl-1">
                                  <i className="la la-user" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              className="col-sm-3 form-control-label"
                              htmlFor="inputEmail1"
                            >
                              Email
                            </label>
                            <div className="col-sm-9">
                              <div className="position-relative has-icon-left">
                                <input
                                  className="form-control"
                                  type="email"
                                  id="inputEmail1"
                                  placeholder="john@example.com"
                                />
                                <div className="form-control-position pl-1">
                                  <i className="la la-envelope-o" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              className="col-sm-3 form-control-label"
                              htmlFor="inputMessage1"
                            >
                              Message
                            </label>
                            <div className="col-sm-9">
                              <div className="position-relative has-icon-left">
                                <textarea
                                  className="form-control"
                                  id="inputMessage1"
                                  rows={2}
                                  placeholder="Simple Textarea"
                                  defaultValue={""}
                                />
                                <div className="form-control-position pl-1">
                                  <i className="la la-commenting-o" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 mb-1">
                              <button
                                className="btn btn-info float-right"
                                type="button"
                              >
                                <i className="la la-paper-plane-o" /> Send{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </li>
                  </ul>
                </li>
                <li className="nav-item nav-search">
                  <Link className="nav-link nav-link-search" to="#">
                    <i className="ficon ft-search" />
                  </Link>
                  <div className="search-input">
                    <input
                      className="input"
                      type="text"
                      placeholder="Explore Farmer Spot..."
                    />
                  </div>
                </li>
              </ul>
              <ul className="nav navbar-nav float-right">
                <li
                  className={
                    Profilebtn
                      ? "dropdown dropdown-user nav-item show "
                      : "dropdown dropdown-user nav-item"
                  }
                  onClick={profilebtnfun}
                >
                  <Link
                    className="dropdown-toggle nav-link dropdown-user-link"
                    to="#"
                    data-toggle="dropdown"
                  >
                    <span className="mr-1">
                      Hello,
                      <span className="user-name text-bold-700">John Doe</span>
                    </span>
                    <span className="avatar avatar-online">
                      <img
                        src="../../../app-assets/images/portrait/small/avatar-s-19.png"
                        alt="avatar"
                      />
                      <i />
                    </span>
                  </Link>
                  <div
                    className={
                      Profilebtn
                        ? "dropdown-menu dropdown-menu-right show"
                        : "dropdown-menu dropdown-menu-right"
                    }
                  >
                    <Link className="dropdown-item" to={'/editprofile'}>
                      <i className="ft-user" /> Edit Profile
                    </Link>
                    <Link className="dropdown-item" to="#">
                      <i className="ft-mail" /> My Inbox
                    </Link>
                    <Link className="dropdown-item" to="#">
                      <i className="ft-check-square" /> Task
                    </Link>
                    <Link className="dropdown-item" to="#">
                      <i className="ft-message-square" /> Chats
                    </Link>
                    <div className="dropdown-divider" />
                    <Link
                      className="dropdown-item"
                      onClick={() => {
                        toast.success("Logout SuccessFully");
                        localStorage.clear();
                      }}
                      to="/login"
                    >
                      <i className="ft-power" /> Logout
                    </Link>
                  </div>
                </li>
                <li className="dropdown dropdown-language nav-item">
                  <Link
                    className="dropdown-toggle nav-link"
                    id="dropdown-flag"
                    to="#"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="flag-icon flag-icon-gb" />
                    <span className="selected-language" />
                  </Link>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdown-flag"
                  >
                    <Link className="dropdown-item" to="#">
                      <i className="flag-icon flag-icon-gb" /> English
                    </Link>
                    <Link className="dropdown-item" to="#">
                      <i className="flag-icon flag-icon-fr" /> French
                    </Link>
                    <Link className="dropdown-item" to="#">
                      <i className="flag-icon flag-icon-cn" /> Chinese
                    </Link>
                    <Link className="dropdown-item" to="#">
                      <i className="flag-icon flag-icon-de" /> German
                    </Link>
                  </div>
                </li>
                <li className="dropdown dropdown-notification nav-item">
                  <Link
                    className="nav-link nav-link-label"
                    to="#"
                    data-toggle="dropdown"
                  >
                    <i className="ficon ft-bell" />
                    <span className="badge badge-pill badge-default badge-danger badge-default badge-up badge-glow">
                      5
                    </span>
                  </Link>
                  <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right">
                    <li className="dropdown-menu-header">
                      <h6 className="dropdown-header m-0">
                        <span className="grey darken-2">Notifications</span>
                      </h6>
                      <span className="notification-tag badge badge-default badge-danger float-right m-0">
                        5 New
                      </span>
                    </li>
                    <li className="scrollable-container media-list w-100">
                      <Link to="#">
                        <div className="media">
                          <div className="media-left align-self-center">
                            <i className="ft-plus-square icon-bg-circle bg-cyan" />
                          </div>
                          <div className="media-body">
                            <h6 className="media-heading">
                              You have new order!
                            </h6>
                            <p className="notification-text font-small-3 text-muted">
                              Lorem ipsum dolor sit amet, consectetuer elit.
                            </p>
                            <small>
                              <time
                                className="media-meta text-muted"
                                dateTime="2015-06-11T18:29:20+08:00"
                              >
                                30 minutes ago
                              </time>
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#">
                        <div className="media">
                          <div className="media-left align-self-center">
                            <i className="ft-download-cloud icon-bg-circle bg-red bg-darken-1" />
                          </div>
                          <div className="media-body">
                            <h6 className="media-heading red darken-1">
                              99% Server load
                            </h6>
                            <p className="notification-text font-small-3 text-muted">
                              Aliquam tincidunt mauris eu risus.
                            </p>
                            <small>
                              <time
                                className="media-meta text-muted"
                                dateTime="2015-06-11T18:29:20+08:00"
                              >
                                Five hour ago
                              </time>
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#">
                        <div className="media">
                          <div className="media-left align-self-center">
                            <i className="ft-alert-triangle icon-bg-circle bg-yellow bg-darken-3" />
                          </div>
                          <div className="media-body">
                            <h6 className="media-heading yellow darken-3">
                              Warning notifixation
                            </h6>
                            <p className="notification-text font-small-3 text-muted">
                              Vestibulum auctor dapibus neque.
                            </p>
                            <small>
                              <time
                                className="media-meta text-muted"
                                dateTime="2015-06-11T18:29:20+08:00"
                              >
                                Today
                              </time>
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#">
                        <div className="media">
                          <div className="media-left align-self-center">
                            <i className="ft-check-circle icon-bg-circle bg-cyan" />
                          </div>
                          <div className="media-body">
                            <h6 className="media-heading">Complete the task</h6>
                            <small>
                              <time
                                className="media-meta text-muted"
                                dateTime="2015-06-11T18:29:20+08:00"
                              >
                                Last week
                              </time>
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#">
                        <div className="media">
                          <div className="media-left align-self-center">
                            <i className="ft-file icon-bg-circle bg-teal" />
                          </div>
                          <div className="media-body">
                            <h6 className="media-heading">
                              Generate monthly report
                            </h6>
                            <small>
                              <time
                                className="media-meta text-muted"
                                dateTime="2015-06-11T18:29:20+08:00"
                              >
                                Last month
                              </time>
                            </small>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li className="dropdown-menu-footer">
                      <Link
                        className="dropdown-item text-muted text-center"
                        to="#"
                      >
                        Read all notifications
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown dropdown-notification nav-item">
                  <Link
                    className="nav-link nav-link-label"
                    to="#"
                    data-toggle="dropdown"
                  >
                    <i className="ficon ft-mail"> </i>
                  </Link>
                  <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right">
                    <li className="dropdown-menu-header">
                      <h6 className="dropdown-header m-0">
                        <span className="grey darken-2">Messages</span>
                      </h6>
                      <span className="notification-tag badge badge-default badge-warning float-right m-0">
                        4 New
                      </span>
                    </li>
                    <li className="scrollable-container media-list w-100">
                      <Link to="#">
                        <div className="media">
                          <div className="media-left">
                            <span className="avatar avatar-sm avatar-online rounded-circle">
                              <img
                                src="../../../app-assets/images/portrait/small/avatar-s-19.png"
                                alt="avatar"
                              />
                              <i />
                            </span>
                          </div>
                          <div className="media-body">
                            <h6 className="media-heading">Margaret Govan</h6>
                            <p className="notification-text font-small-3 text-muted">
                              I like your portfolio, let's start.
                            </p>
                            <small>
                              <time
                                className="media-meta text-muted"
                                dateTime="2015-06-11T18:29:20+08:00"
                              >
                                Today
                              </time>
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#">
                        <div className="media">
                          <div className="media-left">
                            <span className="avatar avatar-sm avatar-busy rounded-circle">
                              <img
                                src="../../../app-assets/images/portrait/small/avatar-s-2.png"
                                alt="avatar"
                              />
                              <i />
                            </span>
                          </div>
                          <div className="media-body">
                            <h6 className="media-heading">Bret Lezama</h6>
                            <p className="notification-text font-small-3 text-muted">
                              I have seen your work, there is
                            </p>
                            <small>
                              <time
                                className="media-meta text-muted"
                                dateTime="2015-06-11T18:29:20+08:00"
                              >
                                Tuesday
                              </time>
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#">
                        <div className="media">
                          <div className="media-left">
                            <span className="avatar avatar-sm avatar-online rounded-circle">
                              <img
                                src="../../../app-assets/images/portrait/small/avatar-s-3.png"
                                alt="avatar"
                              />
                              <i />
                            </span>
                          </div>
                          <div className="media-body">
                            <h6 className="media-heading">Carie Berra</h6>
                            <p className="notification-text font-small-3 text-muted">
                              Can we have call in this week ?
                            </p>
                            <small>
                              <time
                                className="media-meta text-muted"
                                dateTime="2015-06-11T18:29:20+08:00"
                              >
                                Friday
                              </time>
                            </small>
                          </div>
                        </div>
                      </Link>
                      <Link to="#">
                        <div className="media">
                          <div className="media-left">
                            <span className="avatar avatar-sm avatar-away rounded-circle">
                              <img
                                src="../../../app-assets/images/portrait/small/avatar-s-6.png"
                                alt="avatar"
                              />
                              <i />
                            </span>
                          </div>
                          <div className="media-body">
                            <h6 className="media-heading">Eric Alsobrook</h6>
                            <p className="notification-text font-small-3 text-muted">
                              We have project party this saturday.
                            </p>
                            <small>
                              <time
                                className="media-meta text-muted"
                                dateTime="2015-06-11T18:29:20+08:00"
                              >
                                last month
                              </time>
                            </small>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li className="dropdown-menu-footer">
                      <Link
                        className="dropdown-item text-muted text-center"
                        to="#"
                      >
                        Read all messages
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {/* working */}
      {/* <div className={ "main-menu menu-fixed menu-dark menu-accordion menu-shadow expanded" } data-scroll-to-active="true"> */}
      {/* <div className={Togglebtn ? "main-menu menu-fixed menu-dark menu-accordion menu-shadow" : "main-menu menu-fixed menu-dark menu-accordion menu-shadow expanded" } data-scroll-to-active="true"> */}
      <div
        className={
          isHovering
            ? "main-menu menu-fixed menu-dark menu-accordion menu-shadow expanded "
            : "main-menu menu-fixed menu-dark menu-accordion menu-shadow  "
        }
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        data-scroll-to-active="true"
      >
        <div className="main-menu-content">
          <Scrollbars style={{ maxWidth: 260, height: "90vh" }}>
            <ul
              className="navigation navigation-main"
              id="main-menu-navigation"
              data-menu="menu-navigation"
            >
              <li
                className={
                  mystate ? " nav-item has-sub open " : " nav-item has-sub "
                }
              >
                <Link to="/" onClick={() => dispatch(navbartoggle(!mystate))}>
                  <i className="la la-home" />

                  <span className="menu-title" data-i18n="nav.dash.main">
                    Dashboard
                  </span>
                  <span className="badge badge badge-info badge-pill float-right mr-2">
                    3{" "}
                  </span>
                </Link>
              </li>
              {/* <li className=" nav-item"><Link  to="#"><i className="la la-television" /><span className="menu-title" data-i18n="nav.templates.main">Templates </span></Link> 
                                <ul className="menu-content">
                                    <li><Link  className="menu-item" to="#" data-i18n="nav.templates.vert.main">Vertical</Link> 
                                        <ul className="menu-content">
                                            <li><Link  className="menu-item" to="../vertical-menu-template" data-i18n="nav.templates.vert.classic_menu">Classic Menu</Link> 
                                            </li>
                                            <li><Link  className="menu-item" to="../vertical-modern-menu-template">Modern Menu</Link> 
                                            </li>
                                            <li><Link  className="menu-item" to="../vertical-compact-menu-template" data-i18n="nav.templates.vert.compact_menu">Compact Menu</Link> 
                                            </li>
                                            <li><Link  className="menu-item" to="../vertical-content-menu-template" data-i18n="nav.templates.vert.content_menu">Content Menu</Link> 
                                            </li>
                                            <li><Link  className="menu-item" to="../vertical-overlay-menu-template" data-i18n="nav.templates.vert.overlay_menu">Overlay Menu</Link> 
                                            </li>
                                        </ul>
                                    </li>
                                    <li><Link  className="menu-item" to="#" data-i18n="nav.templates.horz.main">Horizontal</Link> 
                                        <ul className="menu-content">
                                            <li><Link  className="menu-item" to="../horizontal-menu-template" data-i18n="nav.templates.horz.classic">Classic</Link> 
                                            </li>
                                            <li><Link  className="menu-item" to="../horizontal-menu-template-nav" data-i18n="nav.templates.horz.top_icon">Full Width</Link> 
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li> */}
              <li className=" navigation-header">
                <span data-i18n="nav.category.layouts">Layouts</span>
                <i
                  className="la la-ellipsis-h ft-minus"
                  data-toggle="tooltip"
                  data-placement="right"
                  data-original-title="Layouts"
                />
              </li>
              <li className=" nav-item">
                <Link to="/users">
                  <BiSolidCategory />
                  <span
                    className="menu-title"
                    data-i18n="nav.page_layouts.main"
                  >
                    Users
                  </span>
                </Link>
              </li>
              <li className=" nav-item">
                <Link to="/category">
                  <BiSolidCategory />
                  <span
                    className="menu-title"
                    data-i18n="nav.page_layouts.main"
                  >
                    Category
                  </span>
                </Link>
              </li>
              <li className=" nav-item">
                <Link to="/product">
                  <MdOutlineProductionQuantityLimits />
                  <span className="menu-title" data-i18n="nav.navbars.main">
                    Product
                  </span>
                </Link>
                {/* <ul className="menu-content">
                  <li>
                    <Link 
                      className="menu-item"
                      to="navbar-light.html"
                      data-i18n="nav.navbars.nav_light"
                    >
                      Navbar Light
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="navbar-dark.html"
                      data-i18n="nav.navbars.nav_dark"
                    >
                      Navbar Dark
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="navbar-semi-dark.html"
                      data-i18n="nav.navbars.nav_semi"
                    >
                      Navbar Semi Dark
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="navbar-brand-center.html"
                      data-i18n="nav.navbars.nav_brand_center"
                    >
                      Brand Center
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="navbar-fixed-top.html"
                      data-i18n="nav.navbars.nav_fixed_top"
                    >
                      Fixed Top
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="#"
                      data-i18n="nav.navbars.nav_hide_on_scroll.main"
                    >
                      Hide on Scroll
                    </Link> 
                    <ul className="menu-content">
                      <li>
                        <Link 
                          className="menu-item"
                          to="navbar-hide-on-scroll-top.html"
                          data-i18n="nav.navbars.nav_hide_on_scroll.nav_hide_on_scroll_top"
                        >
                          Hide on Scroll Top
                        </Link> 
                      </li>
                      <li>
                        <Link 
                          className="menu-item"
                          to="navbar-hide-on-scroll-bottom.html"
                          data-i18n="nav.navbars.nav_hide_on_scroll.nav_hide_on_scroll_bottom"
                        >
                          Hide on Scroll Bottom
                        </Link> 
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="navbar-components.html"
                      data-i18n="nav.navbars.nav_components"
                    >
                      Navbar Components
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="navbar-styling.html"
                      data-i18n="nav.navbars.nav_styling"
                    >
                      Navbar Styling
                    </Link> 
                  </li>
                </ul> */}
              </li>
              <li className=" nav-item">
                <Link to="/order">
                  <MdNordicWalking />
                  <span
                    className="menu-title"
                    data-i18n="nav.vertical_nav.main"
                  >
                    Order
                  </span>
                </Link>
              </li>
              <li className=" nav-item">
                <Link to="/role">
                  <MdNordicWalking />
                  <span
                    className="menu-title"
                    data-i18n="nav.vertical_nav.main"
                  >
                    Role
                  </span>
                </Link>
              </li>
              <li className=" nav-item">
                <Link to="/rider">
                  <SiRider />
                  <span className="menu-title" data-i18n="nav.horz_nav.main">
                    Rider
                  </span>
                </Link>
                {/* <ul className="menu-content">
                  <li>
                    <Link 
                      className="menu-item"
                      to="#"
                      data-i18n="nav.horz_nav.horizontal_navigation_types.main"
                    >
                      Navigation Types
                    </Link> 
                    <ul className="menu-content">
                      <li>
                        <Link 
                          className="menu-item"
                          to="../horizontal-menu-template"
                          data-i18n="nav.horz_nav.horizontal_navigation_types.horizontal_left_icon_navigation"
                        >
                          Left Icon Navigation
                        </Link> 
                      </li>
                      <li>
                        <Link 
                          className="menu-item"
                          to="../horizontal-menu-template-nav"
                          data-i18n="nav.horz_nav.horizontal_navigation_types.horizontal_menu_template_nav"
                        >
                          Top Icon Navigation
                        </Link> 
                      </li>
                    </ul>
                  </li>
                </ul> */}
              </li>
              <li className=" nav-item">
                <Link to="/manager">
                  <GrUserManager />
                  <span
                    className="menu-title"
                    data-i18n="nav.page_headers.main"
                  >
                    Employee
                  </span>
                </Link>
                {/* <ul className="menu-content">
                  <li>
                    <Link 
                      className="menu-item"
                      to="headers-breadcrumbs-basic.html"
                      data-i18n="nav.page_headers.headers_breadcrumbs_basic"
                    >
                      Breadcrumbs basic
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="headers-breadcrumbs-top.html"
                      data-i18n="nav.page_headers.headers_breadcrumbs_top"
                    >
                      Breadcrumbs top
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="headers-breadcrumbs-bottom.html"
                      data-i18n="nav.page_headers.headers_breadcrumbs_bottom"
                    >
                      Breadcrumbs bottom
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="headers-breadcrumbs-top-with-description.html"
                      data-i18n="nav.page_headers.headers_breadcrumbs_top_with_description"
                    >
                      Breadcrumbs top with desc
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="headers-breadcrumbs-with-button.html"
                      data-i18n="nav.page_headers.headers_breadcrumbs_with_button"
                    >
                      Breadcrumbs with button
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="headers-breadcrumbs-with-round-button.html"
                      data-i18n="nav.page_headers.headers_breadcrumbs_with_round_button"
                    >
                      Breadcrumbs with button 2
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="headers-breadcrumbs-with-button-group.html"
                      data-i18n="nav.page_headers.headers_breadcrumbs_with_button_group"
                    >
                      Breadcrumbs with buttons
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="headers-breadcrumbs-with-description.html"
                      data-i18n="nav.page_headers.headers_breadcrumbs_breadcrumbs_with_description"
                    >
                      Breadcrumbs with desc
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="headers-breadcrumbs-with-search.html"
                      data-i18n="nav.page_headers.headers_breadcrumbs_breadcrumbs_with_search"
                    >
                      Breadcrumbs with search
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="headers-breadcrumbs-with-stats.html"
                      data-i18n="nav.page_headers.headers_breadcrumbs_breadcrumbs_with_stats"
                    >
                      Breadcrumbs with stats
                    </Link> 
                  </li>
                </ul> */}
              </li>
              <li className=" nav-item">
                <Link to="/permission">
                  <FaPiedPiperPp />
                  <span className="menu-title" data-i18n="nav.footers.main">
                    Permission
                  </span>
                </Link>
                {/* <ul className="menu-content">
                  <li>
                    <Link 
                      className="menu-item"
                      to="footer-light.html"
                      data-i18n="nav.footers.footer_light"
                    >
                      Footer Light
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="footer-dark.html"
                      data-i18n="nav.footers.footer_dark"
                    >
                      Footer Dark
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="footer-transparent.html"
                      data-i18n="nav.footers.footer_transparent"
                    >
                      Footer Transparent
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="footer-fixed.html"
                      data-i18n="nav.footers.footer_fixed"
                    >
                      Footer Fixed
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="footer-components.html"
                      data-i18n="nav.footers.footer_components"
                    >
                      Footer Components
                    </Link> 
                  </li>
                </ul> */}
              </li>
              <li className=" nav-item">
                <Link to="/profile">
                  <CgProfile />
                  <span className="menu-title" data-i18n="nav.footers.main">
                    Profile
                  </span>
                </Link>
                {/* <ul className="menu-content">
                  <li>
                    <Link 
                      className="menu-item"
                      to="footer-light.html"
                      data-i18n="nav.footers.footer_light"
                    >
                      Footer Light
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="footer-dark.html"
                      data-i18n="nav.footers.footer_dark"
                    >
                      Footer Dark
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="footer-transparent.html"
                      data-i18n="nav.footers.footer_transparent"
                    >
                      Footer Transparent
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="footer-fixed.html"
                      data-i18n="nav.footers.footer_fixed"
                    >
                      Footer Fixed
                    </Link> 
                  </li>
                  <li>
                    <Link 
                      className="menu-item"
                      to="footer-components.html"
                      data-i18n="nav.footers.footer_components"
                    >
                      Footer Components
                    </Link> 
                  </li>
                </ul> */}
              </li>
              <li className=" nav-item">
                <Link
                  to="/login"
                  onClick={() => {
                    toast.success("Logout SuccessFully");
                    localStorage.clear();
                  }}
                >
                  <IoLogOut />
                  <span className="menu-title" data-i18n="nav.footers.main">
                    Log out
                  </span>
                </Link>
              </li>
              <li className=" nav-item">
                <Link to="/manageinvoice">
                  <BiSolidCategory />
                  <span className="menu-title" data-i18n="nav.footers.main">
                    Manage invoice
                  </span>
                </Link>
              </li>
            </ul>
          </Scrollbars>
        </div>
      </div>
    </>
  );
};

export default Header;
