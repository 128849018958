import React from 'react'
import Header from '../../layout/Header'
import Footer from '../../layout/Footer'
import RiderCrud from '../../components/rider/RiderCrud'

const Rider = () => {
  return (
    <>
    <Header/>
    <RiderCrud/>
    <Footer/>
    </>
  )
}

export default Rider