import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const EditProfile = () => {

  const token = localStorage.getItem("token");
  

  useEffect(() => {
    axios
      .get(
        "https://ecommerce99.pythonanywhere.com/Admin/Api/profile/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res, "response");
        if (res.data.status === true) {
          setData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err.response)
        console.log(err);
      });
  },[token]);

   
   

  const [state , setState] = useState(true);

  const [data, setData] = useState({
    fname: "",
    lname: "",
    contact: "",
    address: "",
    profile: null,
  });



  function handleInputs(e) {
    const { name, value, files } = e.target;
    if (name === "profile") {
      setData((prev) => ({
        ...prev,
        profile: files[0],
      }));
    } else {
      setData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }  
  }

     /////////////////       chnage Password  //////////////////////
     const [data2, setData2] = useState({
      oldpassword: "",
      newpassword: "",
    
    });

  function handleInputs2(e) {
    const { name, value } = e.target;
    setData2((pre) => ({
      ...pre,
      [name]: value,
    }));
  }



  function handleForm(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fname", data.fname);
    formData.append("lname", data.lname);
    formData.append("contact", data.contact);
    formData.append("address", data.address);
    formData.append("profile", data.profile);

    axios
      .put(
        "https://ecommerce99.pythonanywhere.com/Admin/Api/profile/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res, "response");
        if (res.data.status === true) {
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message)
        console.log(err);
      });
  }


   /////////////////////////////////     Change Password /////////////////// 

   function handleForm2(e) {
    e.preventDefault();
    const formData2 = new FormData();
    formData2.append("oldpassword", data2.oldpassword);
    formData2.append("newpassword", data2.newpassword);
    console.log(formData2);
    axios
      .put(
        "https://ecommerce99.pythonanywhere.com/Admin/Api/changePassword/",
        formData2,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res, "response");
        if (res.data.status === true) {
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.error)
        console.log(err.response.data.error);
      });
  }
   
  return (
    <>
  
    <div className="main-color">
      <div className="d-flex flex-column justify-content-center">
      <div className="d-flex">
        <div className="d-flex flex-row justify-content-around align-items-center w-50 mx-auto">
       <div><button type="button" className="btncp" onClick={() => setState(true)}>Edit Profile</button></div>
       <div><button type="button" class="btncp" onClick={() => setState(false)}>Change Password</button></div>
       </div>
      </div>
      </div>
      {
       state ? 
      <div className="container pb-3 mt-md-3">
        <div className="row p-sm-5 p-md-3 p-3  p-lg-0 p-xl-0 p-md-0  d-flex justify-content-center">
          <div className="col-sm-12 col-12 col-lg-6 col-md-12 shadow">
            <h1 className="text-center edit">Edit Profile</h1>
        <form className="login-main-form" onSubmit={handleForm}>
          <label className='lable'>First Name</label>
          <input
            required
            className="input"
            type="text"
            name="fname"
            value={data?.fname}
            id="fname"
            onChange={handleInputs}
            placeholder="Enter your FName"
          />
        <label className='lable'>Last Name</label>
          <input
            required
            className="input"
            type="text"
            name="lname"
            value={data?.lname}
            id="lname"
            onChange={handleInputs}
            placeholder="Enter your lName"
          />
        <label className='lable'>contact</label>
          <input
             required
            className="input"
            type="number"
            name="contact"
            value={data?.contact}
            id="contact"
            onChange={handleInputs}
            placeholder="Enter your Number"
          />
          <label className='lable'>Address</label>
          <input
            required
            className="input"
            type="text"
            name="address"
            value={data?.address}
            id="address"
            onChange={handleInputs}
            placeholder="Enter your Address"
          />
        <label className='lable'>File</label>
          <input
            required
            className="input"
            type="file"
            name="profile"
            id="profile"
            value={null}
            onChange={handleInputs}
            placeholder=""
          />
          <div className="d-flex justify-content-end mt-2">
            <button type="submit" className="btn rounded bg-dark text-white font-weight-bold">
              Edit Profile
            </button>
          </div>
        </form>
        </div>
        </div>
      </div> 
       : 
       <> 
      <div className="container pb-3">
        <div className="row p-sm-5 p-md-3 p-3  p-lg-0 p-xl-0 p-md-0  d-flex justify-content-center">
          <div className="col-sm-12 mt-3 col-12 col-lg-6 col-md-12 shadow">
            <h1 className="text-center edit">Change Password</h1>
        <form className="login-main-form mt-2" onSubmit={handleForm2}>
        <label className='lable'>Old Password</label>
          <input
            required
            className="input"
            type="text"
            name="oldpassword"
            value={data2?.oldpassword}
            id="oldpassword"
            onChange={handleInputs2}
            placeholder="Enter your Old Password"
          />
        <label className='lable mt-1'>New Password</label>
          <input
            className="input"
            type="text"
            name="newpassword"
            value={data2?.newpassword}
            id="newpassword"
            onChange={handleInputs2}
            placeholder="Enter your New Password"
            required
          />
          <div className="d-flex justify-content-end mt-2">
            <button  type="submit" className="btn rounded bg-dark text-white font-weight-bold">
              Change Password
            </button>
          </div>
        </form>
        </div>
        </div>
      </div> 
      </> 
       }
    </div>
     </>
     
  );
};

export default EditProfile;
