

import React, { useState, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PencilSquare, Eye, Trash,
  //  Box 
  } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import { 
  // IconButton, 
  MenuItem, 
  // Tooltip 
} from "@mui/material";
import Header from "../../layout/Header";
import ClearIcon from '@mui/icons-material/Clear';
import { MdCancel } from "react-icons/md";



const validationSchema = Yup.object({
  Manager_Name: Yup.string().min(2).max(25).required(" please enter your Manager Name"),
  Role: Yup.string().required(" please enter your Role"),
  Email:Yup.string().email().required("Please Enter Email"),
  Password: Yup.string().required("Password is required"),
  Phone_Number: Yup.string().required('Phone number is required'),


  
});

const ManagerContent = () => {
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  // const handleShow3 = () => setShow3(true);
  const [selectedUser, setSelectedUser] = useState(null);

  
  const handlePreviewClick = (user) => {
    setSelectedUser(user)
};

  const [modalData, setModalData] = useState({
    show: false,
    formData: {
     Manager_Name: "",
     Email: "",
     Role: "",
     Phone_Number:"",
     Password:""
    },
    isEditMode: false,
  });

  const [tableData, setTableData] = useState([]);

  const uniqueIdGenerator = () => uuidv4();

  const columns = useMemo(
    () => [
      {
        accessorKey: "Manager_Name",
        header: "Employee Name",
        size: 150,
      },
      {
        accessorKey: "Email",
        header: "Email",
        size: 150,
      },
      {
        accessorKey: "Phone_Number",
        header: "Phone",
        size: 130,
      },
      {
        accessorKey: "ImageUrl",
        header: "Password",
        size: 150,
      },
      {
        accessorKey: "Role",
        header: "Role",
        size: 150,
      },
    
     
    ],
    []
  );

  const handleEdit = (data) => {
    setModalData({
      show: true,
      formData: { ...data },
      isEditMode: true,
    });
  };

  const handleView = (data) => {
    console.log("View clicked", data);
  };

  const handleDelete = (id) => {
    const updatedData = tableData.filter((item) => item.id !== id);
    setTableData(updatedData);
  };

  const handleClose = () => {
    setModalData((prevData) => ({
      ...prevData,
      show: false,
    }));
  };

  const handleFormSubmit = () => {
    handleSubmit();
    const formValues = values;
    if (!errors || Object.keys(errors).length === 0) {
      if (modalData.isEditMode) {
        const updatedData = tableData.map((item) =>
          item.id === modalData.formData.id ? { ...item, ...formValues } : item
        );
        setTableData(updatedData);
      } else {
        setTableData((prevData) => [
          ...prevData,
          {
            id: uniqueIdGenerator(),
            ...formValues,
            edit: (
              <Button variant="link" onClick={() => handleEdit(formValues)}>
                <PencilSquare />
              </Button>
            ),
            view: (
              <Button variant="link" onClick={() => handleView()}>
                <Eye />
              </Button>
            ),
            delete: (
              <Button
                variant="link"
                onClick={() => handleDelete(formValues.id)}
              >
                <Trash />
              </Button>
            ),
          },
        ]);
      }

      handleClose();
    }
  };

  const { values, errors, touched, handleBlur, handleSubmit, handleChange } =
    useFormik({
      initialValues: modalData.formData,
      validationSchema: validationSchema,
      onSubmit: () => {},
    });

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => [
      <>
        <div className="d-flex">
          <MenuItem key="edit" onClick={() => handleEdit(row.original.data)}>
            <PencilSquare />
          </MenuItem>
          
          <MenuItem key="view" onClick={() => handleView(row.original)}>
          <Eye  onClick={handlePreviewClick}/>
          </MenuItem>
          
          <MenuItem key="delete" onClick={() => handleDelete(row.original.id)}>
            <Trash />
          </MenuItem>
          
        </div>
      </>
    ],
  });

  return (
    <>
    <Header/>


      <div className="app-content content">
        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">

            <div className="container ">
 
             {/* ////////////////////////////////  Model Preview /////////////////////////// */}

             <Modal show={show3} onHide={handleClose3}>
                            <Modal.Header >
                                <Modal.Title>Manager Detail</Modal.Title>
                                <Modal.Title className='cursor-pointer' onClick={handleClose3}><ClearIcon /></Modal.Title>
                            </Modal.Header>
                            {selectedUser && (
                        <div className="mt-1 mx-3 mb-2 " >
                            <div className='p-2'>
                            <h2 className='font-weight-bold'>Preview</h2>
                            <p className='mt-1'>    
                                <strong>Employee_Name</strong> {selectedUser.Manager_Name }
                            </p>
                            <p>
                                <strong>Email :</strong> {selectedUser.Email}
                            </p>
                            <p>
                                <strong>Password:</strong> {selectedUser.ImageUrl}
                            </p>
                            <p>
                                <strong>Role:</strong> {selectedUser.ImageUrl}
                            </p>
                            <p>
                                <strong>Phone_Number :</strong> {selectedUser.Phone_Number}
                            </p>
                           
                            {/* Add image tag here if you want to display the image */}
                            
                            </div>
                        </div>
                    )}
                        </Modal>


                        {/* ////////////////       ENd //////////////////////////// */}
  


             <div className="mb-2">
               <Button variant="primary"
               onClick={() => {
                setModalData({
                  formData: {
                 Manager_Name: "",
                 Email: "",
                 Role: "",
                 Phone_Number:"",
                 Password:""
                  },
                  isEditMode: false,
                  show: true,
                });
              }}>
                 Add Employee +
               </Button>
             </div>
           </div>
            <MaterialReactTable
              table={table}
            />
          </div>
        </div>
      </div>
      <Modal show={modalData.show} onHide={handleClose}>
        <Modal.Header style={{ backgroundColor: "#372f2f", color: "white" }}>
          <Modal.Title><h3 className="text-white">Add Manage</h3></Modal.Title>
          <MdCancel size={30} onClick={handleClose}/>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <div className="d-flex justify-content-center mt-1 justify-content-around">
            <Form.Group className="mb-1" controlId="Name">
              <Form.Label>Employee_Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Manager_Name"
                name="Manager_Name"
                value={values.Manager_Name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.Manager_Name && touched.Manager_Name ? (
                <p className="color-change mt-1">{errors.Manager_Name}</p>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-1" controlId="name">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email"
                name="Email"
                value={values.Email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.Email && touched.Email ? (
                <p className="color-change mt-1">{errors.Email}</p>
              ) : null}
            </Form.Group>
            </div>
            <div className="d-flex justify-content-center justify-content-around">
            <Form.Group className="mb-1" controlId="Vehicle">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                name="Password"
                value={values.Password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.Password && touched.Password ? (
                <p className="color-change mt-1">{errors.Password}</p>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-1" controlId="Role">
              <Form.Label>Role</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your Role"
                name="Role"
                value={values.Role}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.Role && touched.Role ? (
                <p className="color-change mt-1">{errors.Role}</p>
              ) : null}
            </Form.Group>
            </div>
            <div className="container px-2">
            <Form.Group className="mb-2" controlId="Name">
              <Form.Label>Phone_Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your Phone_Number"
                name="Phone_Number"
                value={values.Phone_Number}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.Phone_Number && touched.Phone_Number ? (
                <p className="color-change mt-1">{errors.Phone_Number}</p>
              ) : null}
            </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#372f2f"}}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleFormSubmit}>
            submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManagerContent;

  
  




