// import logo from './logo.svg';
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Dashboard from "./pages/dashboardpages/Dashboard";

import CategoryContent from "./pages/auth/Category";
import ManagerContent from "./pages/auth/Manager";
import Product from "./pages/product/Product";
import Rider from "./pages/riderpage/Rider";
import Order from "./pages/orderpage/Order";
import Role from "./pages/rolepage/Role";
import Users from "./pages/userspage/Users";
import Login from "./pages/login/Login";
import Protected from "./pages/protected/Protected";
import Forget from "./pages/forget/Forget";
import Otp from "./pages/otppage/Otp";
import Reset from "./pages/resetpage/Reset";
import EditProfile from "./pages/EditProfile/Editprofile";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={ <Protected Component={Dashboard}/> } />
          <Route path="/login" element={<Login />} />
          <Route path="/forget" element={<Forget />} />
          <Route path="/otp/:id" element={<Otp />} />
          <Route path="/resetpassword/:id" element={<Reset />} />
          <Route path="/users" element={<Protected Component ={Users} />} />
          <Route path="/editprofile" element={<Protected Component ={EditProfile} />} />
          <Route path="/product" element={ <Protected Component={Product} />} />
          <Route path="/rider" element={<Protected Component= {Rider} />} />
          <Route path="/order" element={<Protected Component={Order}  />} />
          <Route path="/role" element={<Protected Component= {Role} />} />
          <Route path="/Category" element={< Protected Component={CategoryContent} />} />
          <Route path="/Manager" element={<Protected Component = {ManagerContent} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
