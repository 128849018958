import React from 'react'
import Header from '../../layout/Header'
import UsersCrud from '../../components/userscomponent/UsersCrud'
import Footer from '../../layout/Footer'

const Users = () => {
  return (
    <>
    <Header/>
    <UsersCrud/>
    <Footer/>
    </>
  )
}

export default Users