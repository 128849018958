import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const Login = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  function handleInputs(e) {
    const { name, value } = e.target;
    setData((pre) => ({
      ...pre,
      [name]: value,
    }));
  }
  function handleForm(e) {
    e.preventDefault();
    console.log(data, "data");
    const Formdata = new FormData();
    Formdata.append("email", data.email);
    Formdata.append("password", data.password);
    axios
      .post("https://ecommerce99.pythonanywhere.com/Admin/login/", Formdata)
      .then((res) => {
        console.log(res, "response");
        if (res.data.status === true) {
          toast.success(res.data.message);
          localStorage.setItem("token", res.data.token);
          setData({
            email: "",
            password: "",
          });
          navigate("/");
        }
      })
      .catch((err) => {
        // alert("put correct email ", err);
        toast.error("Wrong password");
      });
  }
  return (
    <div className="container">
   <h1 className="text-center fs-sm-2">Markaz-e-hiraj </h1>
      <div className="row p-sm-5 p-md-3 p-3 mt-2 p-lg-0 p-xl-0 p-md-0  d-flex justify-content-center" >
        <div className="col-sm-12 col-12 col-lg-6 col-md-6 form-shadow">
          <h1 className="text-center edit">Login</h1>
          <div className="">
            <form className="" onSubmit={handleForm}>
              <div className="d-flex flex-column">
                <label className="lable2">Email</label>
              <input
                className="input"
                type="email"
                name="email"
                value={data?.email}
                id="email"
                onChange={handleInputs}
                placeholder="Enter your Email"
              />
            <label className="lable2 mt-2">Password</label>
              <input
                className="input"
                type="password"
                name="password"
                id="password"
                value={data?.password}
                onChange={handleInputs}
                placeholder="Password"
              />
              </div>
              <br />
              <div className="d-flex justify-content-end">
                <button
                  onClick={handleForm}
                  type="submit"
                  className="login-btn btn rounded bg-dark text-white font-weight-bold"
                >
                  Login
                </button>
                <button
                  onClick={()=>navigate('/forget')}
                  type="submit"
                  className="btn rounded bg-dark text-white font-weight-bold"
                >
                  forget password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
