import React, { useState, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { X } from "react-bootstrap-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PencilSquare, Eye, Trash } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import { IconButton, MenuItem } from "@mui/material";

const validationSchema = Yup.object({
  role: Yup.string().min(2).max(25).required("Please enter your role"),

  description: Yup.string()
    .min(10)
    .max(255)
    .required("Please enter a description"),
});

const RoleCrud = () => {
  const [modalData, setModalData] = useState({
    show: false,
    formData: {
      role: "",
      description: "",
    },
    isEditMode: false,
  });

  const [tableData, setTableData] = useState([]);

  const uniqueIdGenerator = () => uuidv4();

  const columns = useMemo(
    () => [
      {
        accessorKey: "role",
        header: "Role",
        size: 300,
      },

      {
        accessorKey: "description",
        header: "Description",
        size: 300,
      },
    ],
    []
  );

  // ...

  const handleEdit = (data) => {
    setModalData({
      show: true,
      formData: { ...data },
      isEditMode: true,
    });
  };

  const handleView = (data) => {
    console.log("View clicked", data);
  };

  const handleDelete = (id) => {
    const updatedData = tableData.filter((item) => item.id !== id);
    setTableData(updatedData);
  };
  const handleClose = () => {
    setModalData((prevData) => ({
      ...prevData,
      show: false,
    }));
  };

  const handleFormSubmit = () => {
    handleSubmit();
    const formValues = values;
    if (!errors || Object.keys(errors).length === 0) {
      if (modalData.isEditMode) {
        const updatedData = tableData.map((item) =>
          item.id === modalData.formData.id ? { ...item, ...formValues } : item
        );
        setTableData(updatedData);
      } else {
        setTableData((prevData) => [
          ...prevData,
          {
            id: uniqueIdGenerator(),
            ...formValues,
            edit: (
              <Button variant="link" onClick={() => handleEdit(formValues)}>
                <PencilSquare />
              </Button>
            ),
            view: (
              <Button variant="link" onClick={() => handleView(formValues)}>
                <Eye />
              </Button>
            ),
            delete: (
              <Button
                variant="link"
                onClick={() => handleDelete(formValues.id)}
              >
                <Trash />
              </Button>
            ),
          },
        ]);
      }

      handleClose();
    }
  };

  const { values, errors, touched, handleBlur, handleSubmit, handleChange } =
    useFormik({
      initialValues: modalData.formData,
      validationSchema: validationSchema,
      onSubmit: () => {},
    });
  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => [
      <>
        <div className="d-flex">
          <MenuItem key="edit" onClick={() => handleEdit(row.original)}>
            <PencilSquare />
          </MenuItem>
          ,
          <MenuItem key="view" onClick={() => handleView(row.original)}>
            <Eye />
          </MenuItem>
          ,
          <MenuItem key="delete" onClick={() => handleDelete(row.original.id)}>
            <Trash />
          </MenuItem>
        </div>
      </>,
    ],
  });
  return (
    <>
      <div className="app-content content">
        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">
            <div>
              <h1
                style={{
                  fontWeight: "700",
                  fontSize: "30px",
                  fontFamily: "fangsong",
                }}
              >
                Role
              </h1>
              <p
                style={{
                  fontSize: "larger",
                  color: "black",
                  fontFamily: "fangsong",
                }}
              >
                Compared to an industry role player like Aldean, though, Bryan
                is a grassroots hero who writes and produces his own songs, and
                seems determined to bring a more homespun and individual sound
                to the mainstream.The Gossip Girl star, 36, shared a carousel of
                snaps featuring the Ugly Betty alum on Monday as the pair
                attended a special screening to celebrate 39-year-old Ferrera’s
                role in the Barbie movie.Erykah Badu’s Life in Looks Features a
                Powerful Array of Black and African Designers Fellow creatives
                in the couple’s circle played an integral role in helping bring
                the wedding to life.
              </p>
              <h1
                style={{
                  fontWeight: "700",
                  fontSize: "30px",
                  fontFamily: "fangsong",
                }}
              >
                Description
              </h1>
              <p
                style={{
                  fontSize: "larger",
                  color: "black",
                  fontFamily: "fangsong",
                }}
              >
                These examples are programmatically compiled from various online
                sources to illustrate current usage of the word 'description.'
                Any opinions expressed in the examples do not represent those of
                Merriam-Webster or its editors. Send us feedback about these
                examples.
              </p>
            </div>

            <div className="add-product">
            <div className="mb-2">
               <Button variant="primary"
               style={{marginTop:'-14px'}}
              //  className="on-click-btn"
               onClick={() => {
                setModalData({
                  formData: {
                    name: "",
                    category: "",
                    price: "",
                  },
                  isEditMode: false,
                  show: true,
                });
              }}
              >
                 Add Role +
               </Button>
             </div>
            
            </div>
 
            <MaterialReactTable table={table} />
          </div>
        </div>
      </div>
      <Modal show={modalData.show} onHide={handleClose}>
        <Modal.Header style={{ backgroundColor: "#372f2f", color: "white" }}>
          <Modal.Title><h3 className="text-white">Add your Role</h3></Modal.Title>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <X />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="role">
              <Form.Label>Role Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter role name"
                name="role"
                value={values.role}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.role && touched.role ? (
                <p className="color-change">{errors.role}</p>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description"
                name="description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.description && touched.description ? (
                <p className="color-change">{errors.description}</p>
              ) : null}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#372f2f"}}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleFormSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RoleCrud;
