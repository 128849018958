import React from 'react'
import Header from '../../layout/Header'
import Footer from '../../layout/Footer'
import OrderCrud from '../../components/ordercomponent/OrderCrud'

const Order = () => {
  return (
    <>
    <Header/>
    <OrderCrud/>
    <Footer/>
    </>
  )
}

export default Order