import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import axios from "axios";
import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const Otp = () => {
  const { id } = useParams();
  const [OTP, setOTP] = useState("");
  const navigate = useNavigate();

  const handleSendOtp = async () => {
    console.log("OTP",OTP);
    const data = new FormData();
    data.append("otp", OTP);
    data.append("id", id);
   
    try {
      const response = await axios.post(
        `https://ecommerce99.pythonanywhere.com/Admin/checkOtp/`,
        data
      );
      console.log("reult",response);
      if (response.data.status === true) {
              toast.success("OTP sent successfully");
        navigate(`/resetpassword/${response.data.id}`);
      }
      
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("Failed to send OTP");
     
    }
  };

  return (
    <div className="otp-container">
      <div className="otp-box">
        <OTPInput
          value={OTP}
          onChange={setOTP}
          autoFocus
          OTPLength={6}
          otpType="number"
          disabled={false}
          className="otp-input"
        />
        <ResendOTP onResendClick={handleSendOtp} className="resend-button" />
        <button onClick={handleSendOtp}>Verify OTP</button>
      </div>
    </div>
  );
};

export default Otp;


