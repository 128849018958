import React from 'react'
import Header from '../../layout/Header'
import RoleCrud from '../../components/rolecomponent/RoleCrud'
import Footer from '../../layout/Footer'

const Role = () => {
  return (
    <>
    <Header/>
    <RoleCrud/>
    <Footer/>
    </>
  )
}

export default Role