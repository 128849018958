import React, { useState, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import { X } from "react-bootstrap-icons";

import { PencilSquare, Eye, Trash } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import { IconButton, MenuItem } from "@mui/material";

const validationSchema = Yup.object({
  name: Yup.string().min(2).max(25).required("Please enter your name"),
  item: Yup.string().min(2).max(25).required("Please enter your item"),
  price: Yup.number().required("Please enter your price"),
  date: Yup.date().required("Please enter a date"),
  time: Yup.string().required("Please enter a time"),
});

const OrderCrud = () => {
  const [modalData, setModalData] = useState({
    show: false,
    formData: {
      name: "",
      item: "",
      price: "",
      date: "",
      time: "",
    },
    isEditMode: false,
  });

  const [tableData, setTableData] = useState([]);

  const uniqueIdGenerator = () => uuidv4();

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "User Name",
        size: 150,
      },
      {
        accessorKey: "item",
        header: "Item",
        size: 150,
      },
      {
        accessorKey: "price",
        header: "Price",
        size: 150,
      },
      {
        accessorKey: "date",
        header: "Date",
        size: 150,
      },
      {
        accessorKey: "time",
        header: "Time",
        size: 150,
      },
    ],
    []
  );

  const handleEdit = (data) => {
    setModalData({
      show: true,
      formData: { ...data },
      isEditMode: true,
    });
  };

  const handleView = (data) => {
    console.log("View clicked", data);
  };

  const handleDelete = (id) => {
    const updatedData = tableData.filter((item) => item.id !== id);
    setTableData(updatedData);
  };
  const handleClose = () => {
    setModalData((prevData) => ({
      ...prevData,
      show: false,
    }));
  };

  const handleFormSubmit = () => {
    handleSubmit();
    const formValues = values;
    if (!errors || Object.keys(errors).length === 0) {
      if (modalData.isEditMode) {
        const updatedData = tableData.map((item) =>
          item.id === modalData.formData.id ? { ...item, ...formValues } : item
        );
        setTableData(updatedData);
      } else {
        setTableData((prevData) => [
          ...prevData,
          {
            id: uniqueIdGenerator(),
            ...formValues,
            edit: (
              <Button variant="link" onClick={() => handleEdit(formValues)}>
                <PencilSquare />
              </Button>
            ),
            view: (
              <Button variant="link" onClick={() => handleView(formValues)}>
                <Eye />
              </Button>
            ),
            delete: (
              <Button
                variant="link"
                onClick={() => handleDelete(formValues.id)}
              >
                <Trash />
              </Button>
            ),
          },
        ]);
      }

      handleClose();
    }
  };

  const { values, errors, touched, handleBlur, handleSubmit, handleChange } =
    useFormik({
      initialValues: modalData.formData,
      validationSchema: validationSchema,
      onSubmit: () => {},
    });
  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => [
      <>
        <div className="d-flex">
          <MenuItem key="edit" onClick={() => handleEdit(row.original)}>
            <PencilSquare />
          </MenuItem>
          ,
          <MenuItem key="view" onClick={() => handleView(row.original)}>
            <Eye />
          </MenuItem>
          ,
          <MenuItem key="delete" onClick={() => handleDelete(row.original.id)}>
            <Trash />
          </MenuItem>
        </div>
      </>,
    ],
  });
  return (
    <>
      <div className="app-content content">
        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">
 
          <div className="add-product">
            <div className="mb-2">
               <Button variant="primary"
               style={{marginTop:'-20px'}}
              //  className="on-click-btn"
               onClick={() => {
                setModalData({
                  formData: {
                    name: "",
                    item: "",
                    price: "",
                    date: "",
                    time: "",
                  },
                  isEditMode: false,
                  show: true,
                });
              }}
              >
               Add Order +
               </Button>
             </div>
            
            </div>
           
            <MaterialReactTable
              table={table}
              // render={({ rows, prepareRow }) => (
              //   <table className="table">
              //     <thead>
              //       <tr>
              //         {table.headerGroups.map((headerGroup) => (
              //           <th {...headerGroup.getHeaderGroupProps()}>
              //             {headerGroup.headers.map((column) => (
              //               <span {...column.getHeaderProps()}>
              //                 {column.render("Header")}
              //               </span>
              //             ))}
              //           </th>
              //         ))}
              //       </tr>
              //     </thead>
              //     <tbody {...table.getTableBodyProps()}>
              //       {rows.map((row) => {
              //         prepareRow(row);
              //         return (
              //           <tr {...row.getRowProps()}>
              //             {row.cells.map((cell) => (
              //               <td {...cell.getCellProps()}>
              //                 {cell.column.accessorKey === "edit" ? (
              //                   <Button
              //                     variant="link"
              //                     onClick={() => handleEdit(row.original)}
              //                   >
              //                     <PencilSquare />
              //                   </Button>
              //                 ) : cell.column.accessorKey === "view" ? (
              //                   <Button
              //                     variant="link"
              //                     onClick={() => handleView(row.original)}
              //                   >
              //                     <Eye />
              //                   </Button>
              //                 ) : cell.column.accessorKey === "delete" ? (
              //                   <Button
              //                     variant="link"
              //                     onClick={() => handleDelete(row.original.id)}
              //                   >
              //                     <Trash />
              //                   </Button>
              //                 ) : (
              //                   cell.render("Cell")
              //                 )}
              //               </td>
              //             ))}
              //           </tr>
              //         );
              //       })}
              //     </tbody>
              //   </table>
              // )}
            />
          </div>
        </div>
      </div>
      <Modal show={modalData.show} onHide={handleClose}>
        <Modal.Header style={{ backgroundColor: "#372f2f", color: "white" }}>
          <Modal.Title><h3 className="text-white">Add your Order</h3></Modal.Title>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <X />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-center mt-1 justify-content-around"> 
            <Form.Group className="mb-1" controlId="userName">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter product name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.name && touched.name ? (
                <p className="color-change">{errors.name}</p>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-1" controlId="productItem">
              <Form.Label>Item</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter category"
                name="item"
                value={values.item}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.item && touched.item ? (
                <p className="color-change">{errors.item}</p>
              ) : null}
            </Form.Group>
            </div>
            <div className="d-flex justify-content-center mt-1 justify-content-around"> 
            <Form.Group className="mb-1" controlId="productPrice">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number" // Use type="number" for numeric input
                placeholder="Enter price"
                name="price"
                value={values.price}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.price && touched.price ? (
                <p className="color-change">{errors.price}</p>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-1" controlId="productDate">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date" // Use type="date" for date input
                placeholder="Enter date"
                name="date"
                value={values.date}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.date && touched.date ? (
                <p className="color-change">{errors.date}</p>
              ) : null}
            </Form.Group>
            </div>

            <div className="container px-2">
            <Form.Group className="mb-1" controlId="productTime">
              <Form.Label>Time</Form.Label>
              <Form.Control
                type="time" // Use type="time" for time input
                placeholder="Enter time"
                name="time"
                value={values.time}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.time && touched.time ? (
                <p className="color-change">{errors.time}</p>
              ) : null}
            </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#372f2f"}}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleFormSubmit}>
            Add Order
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderCrud;
