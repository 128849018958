import React, { useState, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Button from "react-bootstrap/Button";
import { X } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PencilSquare, Eye, Trash } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import { IconButton, MenuItem } from "@mui/material";

const validationSchema = Yup.object({
  name: Yup.string().min(2).max(25).required(" please enter your name"),
  email: Yup.string()
    .email("Invalid email address")
    .required("please enter your Email "),
  address: Yup.string().required("please enter your  Address "),
  phone: Yup.number().required(" please enter your Phone number "),
  password: Yup.string()
    .min(4)
    .max(15)
    .required(" please enter your Password "),
});

const UsersCrud = () => {
  const [modalData, setModalData] = useState({
    show: false,
    formData: {
      name: "",
      email: "",
      address: "",
      phone: "",
      password: "",
    },
    isEditMode: false,
  });

  const [tableData, setTableData] = useState([]);

  const uniqueIdGenerator = () => uuidv4();

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Users Name",
        size: 150,
      },
      {
        accessorKey: "email",
        header: "Email Address",
        size: 150,
      },
      {
        accessorKey: "address",
        header: "Address",
        size: 150,
      },
      {
        accessorKey: "phone",
        header: "Phone number",
        size: 150,
      },
      {
        accessorKey: "password",
        header: "Password",
        size: 150,
      },
    ],
    []
  );

  const handleEdit = (data) => {
    setModalData({
      show: true,
      formData: { ...data },
      isEditMode: true,
    });
  };

  const handleView = (data) => {
    console.log("View clicked", data);
  };

  const handleDelete = (id) => {
    const updatedData = tableData.filter((item) => item.id !== id);
    setTableData(updatedData);
  };

  const handleClose = () => {
    setModalData((prevData) => ({
      ...prevData,
      show: false,
    }));
  };

  const handleFormSubmit = () => {
    handleSubmit();
    const formValues = values;
    if (!errors || Object.keys(errors).length === 0) {
      if (modalData.isEditMode) {
        const updatedData = tableData.map((item) =>
          item.id === modalData.formData.id ? { ...item, ...formValues } : item
        );
        setTableData(updatedData);
      } else {
        setTableData((prevData) => [
          ...prevData,
          {
            id: uniqueIdGenerator(),
            ...formValues,
            edit: (
              <Button variant="link" onClick={() => handleEdit(formValues)}>
                <PencilSquare />
              </Button>
            ),
            view: (
              <Button variant="link" onClick={() => handleView(formValues)}>
                <Eye />
              </Button>
            ),
            delete: (
              <Button
                variant="link"
                onClick={() => handleDelete(formValues.id)}
              >
                <Trash />
              </Button>
            ),
          },
        ]);
      }

      handleClose();
    }
  };

  const { values, errors, touched, handleBlur, handleSubmit, handleChange } =
    useFormik({
      initialValues: modalData.formData,
      validationSchema: validationSchema,
      onSubmit: () => {},
    });

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => [
      <>
        <div className="d-flex">
          <MenuItem key="edit" onClick={() => handleEdit(row.original)}>
            <PencilSquare />
          </MenuItem>
          ,
          <MenuItem key="view" onClick={() => handleView(row.original)}>
            <Eye />
          </MenuItem>
          ,
          <MenuItem key="delete" onClick={() => handleDelete(row.original.id)}>
            <Trash />
          </MenuItem>
        </div>
      </>,
    ],
  });

  return (
    <>
      <div className="app-content content">
        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">
            <div className="add-product">
            <div className="mb-2">
               <Button variant="primary"
               style={{marginTop:'-20px'}}
              //  className="on-click-btn"
               onClick={() => {
                setModalData({
                  formData: {
                    name: "",
                    email: "",
                    vehicle: "",
                    license: "",
                    phone: "",
                    password: "",
                  },
                  isEditMode: false,
                  show: true,
                });
              }}
              >
                 Add Users +
               </Button>
             </div>
            
            </div>
            <MaterialReactTable table={table} />
          </div>
        </div>
      </div>
      <Modal show={modalData.show} onHide={handleClose}>
        <Modal.Header style={{ backgroundColor: "#372f2f", color: "white" }}>
          <Modal.Title style={{ color: "white" }}>Add Users</Modal.Title>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <X />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <div className="d-flex justify-content-center mt-1 justify-content-around"> 
            <Form.Group className="mb-1" controlId="Name">
              <Form.Label
              >
                Name
              </Form.Label>
              <Form.Control
              style={{ height: "8vh", borderColor: "#bfa9a9" }}
                type="text"
                placeholder="Enter your name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.name && touched.name ? (
                <p className="color-change">{errors.name}</p>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-1" controlId="Email">
              <Form.Label
              >
                Email Address
              </Form.Label>
              <Form.Control
              style={{ height: "8vh", borderColor: "#bfa9a9" }}
                type="Email"
                placeholder="Enter your Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email ? (
                <p className="color-change">{errors.email}</p>
              ) : null}
            </Form.Group>
            </div>
            <div className="d-flex justify-content-center mt-1 justify-content-around"> 
            <Form.Group className="mb-1" controlId="Address">
              <Form.Label
              >
                Address no:
              </Form.Label>
              <Form.Control
              style={{ height: "8vh", borderColor: "#bfa9a9" }}
                type="text"
                placeholder="Enter  vehicle number"
                name="address"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.address && touched.address ? (
                <p className="color-change">{errors.address}</p>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-1" controlId="Phone">
              <Form.Label
              
              >
                Phone no:
              </Form.Label>
              <Form.Control
              style={{ height: "8vh", borderColor: "#bfa9a9" }}
                type="number"
                placeholder="Enter  phone number"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.phone && touched.phone ? (
                <p className="color-change">{errors.phone}</p>
              ) : null}
            </Form.Group>
            </div>
            <div className="container px-2">
            <Form.Group className="mb-1" controlId="Password">
              <Form.Label
               
              >
                Password
              </Form.Label>
              <Form.Control
                style={{ height: "8vh", borderColor: "#bfa9a9" }}
                type="password"
                placeholder="Enter your password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.password && touched.password ? (
                <p className="color-change">{errors.password}</p>
              ) : null}
            </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#372f2f"}}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleFormSubmit}>
            submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UsersCrud;
