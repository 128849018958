import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Forget = () => {
  const [data, setData] = useState({
    email: "",
  });
  function handleInputs(e) {
    const { name, value } = e.target;
    setData((pre) => ({
      ...pre,
      [name]: value,
    }));
  }
  const navigate = useNavigate();
  function handleForm(e) {
    e.preventDefault();
    const Formdata = new FormData();
    Formdata.append("email", data.email);
    console.log("Formdata", Formdata);
    axios
      .post(
        "https://ecommerce99.pythonanywhere.com/Admin/forgetPassSendMail/",
        Formdata
      )
      .then((res) => {
        console.log(res.data, "response");
        if (res.data.status === true) {
          toast.success(res.data.message);
          localStorage.setItem("token", res.data.token);
          setData({
            email: "",
          });
          navigate(`/otp/${res.data.id} `);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        // alert("put correct email ", err);
      });
  }
  return (
    <div>
      <div className="main-div-login">
        <h1 className="login-second">Markaz-e-hiraj </h1>
        <div className="input-div-login">
          <form className="login-main-form" onSubmit={handleForm}>
            <input
              className="login-input"
              type="email"
              name="email"
              value={data?.email}
              id="email"
              onChange={handleInputs}
              placeholder="Enter your Email"
            />

            <br />
            <div className="d-flex">
              <div className="buttons-login">
                <button
                  onClick={handleForm}
                  type="forget"
                  className="login-button"
                >
                  Confirm Password
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Forget;
